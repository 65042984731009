<template>
  <a class="LargeButton" @click="$emit('click')" :class="{ noIcon: isFalseIcon, avatar: UserAvatar }" :style="`--icon-size:${sizeIcon}`">
    <div class="LargeButtonLeft">
      <div class="LargeButtonIcon" v-if="!isFalseIcon && !UserAvatar"><iconic :name="icon" /></div>
      <div class="LargeButtonAvatar" v-if="UserAvatar">
        <UserAvatar :user="UserAvatar" :size="sizeIcon" />
      </div>
      <div class="LargeButtonText">
        <div class="LargeButtonLabel" v-html="label">{{ label }}</div>
        <div class="LargeButtonDesc" v-html="desc">{{ desc }}</div>
        <slot></slot>
      </div>
    </div>
    <div class="LargeButtonRight">
      <div class="LargeButtonRightIcon" v-if="!rightDesc && !isFalseRightIcon">
        <iconic :name="rightIcon || `chevronRight`" />
      </div>
      <div class="LargeButtonRightDesc" v-if="rightDesc" v-html="rightDesc"></div>
    </div>
    <span class="line"></span>
  </a>
</template>

<script>
export default {
  props: ["label", "desc", "icon", "rightIcon", "iconSize", "rightDesc", "UserAvatar"],
  computed: {
    isFalseIcon: function() {
      return this.icon === "false" || this.icon === false;
    },
    isFalseRightIcon: function() {
      return this.rightIcon === "false" || this.rightIcon === false;
    },
    sizeIcon: function() {
      return this.iconSize || "36px";
    },
  },
};
</script>

<style lang="scss">
$butotonPadding: $mpadding * 1.5;
.LargeButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $mpadding $butotonPadding;
  position: relative;
  width: 100%;
  flex: 1;
  user-select: none;
  overflow: hidden;
  cursor: pointer;
  .LargeButtonIcon {
    width: var(--icon-size);
    max-width: var(--icon-size);
    min-width: var(--icon-size);
    display: flex;
    justify-content: flex-start;
  }
  .LargeButtonLeft {
    display: flex;
    flex-direction: row;
    flex: auto;
    align-items: center;
  }
  .LargeButtonText {
    margin: 0 0 0 0;
  }
  .line {
    display: block;
    border-bottom: 1px solid rgba($alto, 0.5);
    width: 100%;
    position: absolute;
    bottom: 0;
    //right: $mpadding;
    left: calc(var(--icon-size) + #{$butotonPadding * 1.3});
    max-width: calc(100% - calc(var(--icon-size) + #{$butotonPadding * 2}));
  }
  &:active {
    opacity: 0.4;
    background-color: rgba($button_contrast, 0.9);
  }
  .LargeButtonRightIcon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .iconic {
    @include iconic();
  }
  .LargeButtonDesc {
    font-size: 80%;
    opacity: 0.6;
    margin: $mpadding/4 0 0 0;
  }
  &.noIcon .line {
    left: $butotonPadding;
    max-width: calc(100% - #{$butotonPadding * 2});
  }
  &.avatar {
    padding: $mpadding;
    .LargeButtonText {
      padding: 0 $mpadding;
    }
  }

  .LargeButtonRightDesc {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
  }
  &.__deleted {
    pointer-events: none;
    opacity: 0.5;
  }

  &[line="total"] .line,
  &[line="false"] .line,
  &.primary-button .line {
    display: none;
  }

  &[line="total"] {
    border: solid 1px $alto;
  }

  &.primary-button:active {
    opacity: 0.4;
    background-color: $primary_color;
  }

  &[align="top"] {
    align-items: flex-start;
    .LargeButtonLeft {
      align-items: flex-start;
    }
  }
}
</style>
